import type { FC } from 'react';
import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { styled, cssMap } from '@compiled/react';
import { useIntl, defineMessages } from 'react-intl-next';
import invariant from 'tiny-invariant';
import ReactDOM from 'react-dom';

import {
	Card,
	TitleBlock,
	ActionName,
	SmartLinkPosition,
	SmartLinkDirection,
	loadingPlaceholderClassName,
	SmartLinkSize,
} from '@atlaskit/smart-card';
import { CardSSR } from '@atlaskit/smart-card/ssr';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import Popup from '@atlaskit/popup';
import Blanket from '@atlaskit/blanket';
import type { LinkPickerPlugin } from '@atlaskit/link-picker';
import { LazyLinkPicker } from '@atlaskit/link-picker/lazy';
import { token } from '@atlaskit/tokens';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { scrollJustEnoughIntoView } from '@atlaskit/pragmatic-drag-and-drop/element/scroll-just-enough-into-view';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { cx } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives';

import { AtlassianLinkPickerPlugin } from '@atlassian/link-picker-atlassian-plugin';

import { useMatchRoute } from '@confluence/route-manager/entry-points/RouteState';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSearchProvider } from '@confluence/editor-features';
import { START_TOUCH } from '@confluence/navdex';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { useEditShortcut } from './useShortcuts';

export const isPageSSRd = () => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	return process.env.REACT_SSR || window.__SSR_RENDERED__;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragPreview = styled.div({
	'--grid': '8px',
	padding: 'var(--grid) calc(var(--grid) * 2)',
	display: 'flex',
	alignItems: 'center',
	borderRadius: 3,
	boxSizing: 'border-box',
	width: '210px',
	background: token('elevation.surface.raised'),
	justifyContent: 'space-between',
	color: token('color.text.subtle'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& a': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.subtle')} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .actions-button-group': {
		display: 'none',
	},
});

// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ShortcutsItemContainerNav3 = styled.li<{
	draggableState: DraggableState;
}>`
	cursor: default;
	margin: 0;
	position: relative;
	border-radius: 3px;
	max-height: 40px;
	opacity: ${({ draggableState }) => (draggableState === 'dragging' ? 0.6 : 1)};
	/* prettier-ignore */
	&:hover, &:focus, &:focus-within {
    background-color: ${({ draggableState }) =>
		draggableState === 'idle' ? token('color.background.neutral.subtle.hovered') : 'auto'};
  }
	.actions-button-group {
		opacity: 0;
	}

	&[data-is-menu-open='false'] {
		&:hover {
			background-color: ${token('color.background.neutral.subtle.hovered')};
			.actions-button-group {
				button {
					background-color: transparent;
					&:hover {
						background-color: ${token('color.background.neutral.subtle.hovered')};
						svg {
							color: ${token('color.background.neutral.bold')};
						}
					}
					svg {
						color: ${token('color.text.subtlest')};
					}
				}
			}
		}
	}

	&[data-is-menu-open='true'] {
		background-color: ${token('color.background.neutral.subtle.pressed')};
		div[data-smart-block='true'] {
			.actions-button-group {
				opacity: 100%;
				button {
					background-color: ${token('color.background.accent.blue.subtle.pressed')};

					svg {
						color: ${token('color.text.selected')};
					}
				}
			}
		}
	}

	&:hover div .actions-button-group {
		${({ draggableState }) =>
			draggableState === 'idle'
				? `
       opacity: 1;
       transition: opacity ease-in 0.3s;
    `
				: `
      opacity: 0;
    `}
		flex-shrink: 0;
	}

	@keyframes loading-icon {
		from {
			background-position: -20px 0;
		}
		to {
			background-position: 20px 0;
		}
	}

	${() =>
		isPageSSRd()
			? ''
			: `& .${loadingPlaceholderClassName} {
    display: inline-block;
    width: 100%;
    height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0px 42px 0 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    text-decoration: none;
    font: ${token('font.body')};
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 22.3906px;
      height: 22.3906px;
      position: relative;
      top: 6px;
      flex-shrink: 0;
      margin-right: 16px;
      user-select: none;
      background: var(--ds-skeleton-subtle, #f6f7f8);
      background-image: linear-gradient(
        to right,
        var(--ds-skeleton-subtle, #f6f7f8) 0%,
        var(--ds-skeleton, #edeef1) 20%,
        var(--ds-skeleton-subtle, #f6f7f8) 40%,
        var(--ds-skeleton-subtle, #f6f7f8) 100%
      );
      background-repeat: no-repeat;
      background-size: 280% 100%;
      border-radius: 2px;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: loading-icon;
      animation-timing-function: linear;
    }
  }`}

	span[data-testid="smart-element-link-tooltip--container"] {
		width: 100%;
		display: flex;
		flex: 1 1 0;
	}

	&:hover {
		background-color: ${token('color.background.neutral.subtle.hovered')};
	}

	& div[data-smart-link-container='true'] {
		height: 40px;
		padding: 0 10px;

		[data-smart-element-icon='true'],
		[data-smart-element-icon='true'] span,
		[data-smart-element-icon='true'] svg,
		[data-smart-element-icon='true'] img {
			height: 16px;
			min-height: 16px;
			width: 16px;
			min-width: 16px;
			vertical-align: middle;
		}
		height: 36px;
		padding: 0px ${token('space.050')} 0px ${token('space.500')} !important;

		div[data-smart-block='true'] {
			height: 40px;
			gap: ${token('space.100')};
			align-items: center;
			img,
			span[role='img'],
			span[role='img'] svg,
			div[data-smart-element-icon='true'],
			span[data-testid='deleteAction-icon'] {
				height: 16px;
				width: 16px;
				min-height: 16px;
				min-width: 16px;
				max-height: 16px;
				max-width: 16px;
				display: flex;
				align-items: center;
			}
			button {
				svg {
					color: ${token('color.text')};
				}
			}
		}
	}

	div.actions-button-group {
		button {
			padding: 0;
			height: 24px;
			width: 24px;
			min-width: 24px;
		}
	}
	a,
	a:visited,
	a:focus,
	a:hover,
	a:active {
		text-decoration: none;
		color: ${token('color.text.subtle')};
	}
	a:visited {
		color: ${token('color.text.subtle')} !important;
	}
`;

const itemStyles = cssMap({
	base: {
		/* Match Nav4 style */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&': {
			position: 'relative',
			height: '32px',
			borderRadius: '3px',
			marginTop: '0',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:hover, &:has(:focus)': {
			backgroundColor: token('color.background.neutral.subtle.hovered'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& [data-smart-block="true"]': {
			padding: '4px 4px 4px 8px',
			gap: 'initial',
		},
		/* Add a little notch on the left side like Nav4 */
		'&:hover::before': {
			backgroundColor: token('color.background.neutral.hovered'),
			content: ' ',
			position: 'absolute',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			marginTop: '10px',
			width: '2px',
			height: '12px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& a': {
			/* Override the default link color and size */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			color: `${token('color.text.subtle')} !important`,
			font: token('font.body'),
			fontWeight: token('font.weight.medium'),
			/* Position the unresolved link the same as the resolved link */
			paddingBlock: '0',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingLeft: '34px',
			paddingRight: token('space.400'),
			/* Add ... to long unresolved links */
			display: 'block',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& a[data-smart-element="Title"]': {
			paddingInline: token('space.100'),
			display: '-webkit-box',
			/* Restore the title ellipsis for long resolved links */
			whiteSpace: 'normal',
		},
		/* Prevent underlined links */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& a:hover': {
			textDecoration: 'none',
		},
		/* Prevent the hidden link from showing */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& [data-smart-link-container="true"] > a': {
			padding: '0',
		},
		/* Fix vertical alignment for links without ... button */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-smart-block="true"]': {
			height: '24px',
		},
		/* Fix spacing for unresolved links */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-smart-block="true"] > *': {
			minWidth: 'auto',
		},
		/* Match Nav4 action button style */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& button': {
			width: '24px',
			height: '24px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'& button:not(:hover)': {
			backgroundColor: 'transparent',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			color: `${token('color.text.subtle')} !important`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& button svg': {
			width: '12px',
			height: '12px',
		},
	},
	dragging: {
		opacity: '0.6',
	},
	isMenuOpen: {
		/* Match Nav4 actions style */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&&': {
			backgroundColor: token('color.background.neutral.subtle.pressed'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupTrigger = styled.span({
	float: 'right',
	maxWidth: 0,
	maxHeight: 0,
	visibility: 'hidden',
	overflow: 'hidden',
});

const i18n = defineMessages({
	shortcutsQuickActionsRemoveButton: {
		id: 'space-shortcuts.shortcuts.quick-actions.remove',
		defaultMessage: 'Remove',
		description: 'Text for remove button',
	},
	draggableItemLabel: {
		id: 'space-shortcuts.draggable-item.label',
		description: 'aria-label for the draggable page tree item',
		defaultMessage: `{title} draggable item`,
	},
});

type DraggableState = 'idle' | 'generate-preview' | 'dragging';

type ShortcutsItemProps = {
	item: SpaceNavigationQueryShortcutsType;
	index: number;
	spaceId: string | null;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isSpaceAdmin: boolean;
	links: SpaceNavigationQueryShortcutsType[];
	deleteShortcut: (item: SpaceNavigationQueryShortcutsType) => void;
	onDragStart: any;
	onDragEnd: any;
};

//not exported from smartCard for some reason
type AnchorTarget = '_blank' | '_self' | '_top' | '_parent';

const SHORTCUTS_ITEM_TYPE = 'shortcuts-item';

// string begins with 1+ characters other than "/", has a "/", and is followed by one character other than "/"
// OR string begins with "/", is followed by 1 character other than "/", ends with any # of any character
// while ignoring capitalization
const relativeUrlMatch = new RegExp(`^[^\/]+\/[^\/].*$|^\/[^\/].*$`, 'i');

// adding domain to relative confluence URLs so smart links don't error
export const getFullUrl = (url, isExternal) => {
	if (url === null) {
		return url;
	}
	if (!isExternal && relativeUrlMatch.test(url)) {
		return window.location.origin + url;
	}
	// edge case that doesn't match regexp
	if (url.match(`${window.location.protocol}///wiki`)) {
		return window.location.origin + url.replace(`${window.location.protocol}//`, '');
	}
	return url;
};

export const ShortcutsItemComponent: FC<ShortcutsItemProps & WithFlagsProps> = ({
	flags,
	item,
	index,
	spaceId,
	createAnalyticsEvent,
	isSpaceAdmin,
	links,
	deleteShortcut,
	onDragStart,
	onDragEnd,
}) => {
	const isNav4Enabled = useIsNav4Enabled();
	const ref = useRef<HTMLLIElement | null>(null);
	const refPreview = useRef<HTMLDivElement | null>(null);
	const intl = useIntl();
	const CardComponent = isPageSSRd() ? CardSSR : Card;

	let { url, styleClass } = item;
	url = url || ('' as string);
	const { title = '', id } = item;
	//styleClass is the old way of knowing if a link was internal or external
	styleClass = styleClass || '';
	const key = id.concat('-', url || '');

	const matchRoute = useMatchRoute();

	let target: AnchorTarget = '_blank',
		navdexPointType: 'navigationSessionStartTouch' | null = null;
	try {
		const isRelativeUrl = matchRoute(url);
		const includesHostName = url.includes(window.location.hostname);
		const containsExternalStyleClass = styleClass.indexOf('external_link') >= 0;
		//now we also check if the link is relative or contains the pathname, falls back to styleclass
		const isExternal = !(isRelativeUrl || includesHostName) && containsExternalStyleClass;
		url = getFullUrl(url, isExternal) || '';
		target = isExternal ? '_blank' : '_self';
		navdexPointType = !isExternal ? START_TOUCH : null;
	} catch {
		// eslint-disable-next-line no-console
		console.warn('malformed URL:', url);
		//prefer to show a different UI when the link is bad, letting the user know they should update the shortcut...
		//however the smartcard format doesn't really have a way to do this.
	}

	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [draggableState, setDraggableState] = useState<DraggableState>('idle');

	const handleClose = useCallback(() => {
		setIsEditOpen(false);
	}, [setIsEditOpen]);

	const openEdit = useCallback(() => {
		setIsEditOpen(!isEditOpen);
	}, [isEditOpen, setIsEditOpen]);

	const handleSubmit = useEditShortcut(links, flags, spaceId);

	const onSubmit = useCallback(
		(href: string, displayText: string | null) => {
			handleSubmit({
				id,
				url: href,
				title: displayText || '',
			});
			handleClose();
		},
		[handleClose, handleSubmit, id],
	);

	const searchProvider = useSearchProvider();
	const plugins: [LinkPickerPlugin] = useMemo(
		() => [
			new AtlassianLinkPickerPlugin({
				searchProvider,
				activityClientEndpoint: `${window.location.origin}/gateway/api/graphql`,
			}),
		],
		[searchProvider],
	);

	const location = 'confluenceShortcuts';

	const sendShortcutClickedAnalytics = (navdexPointType) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'spaceShortcut',
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navVersion: '3',
					...(navdexPointType && { navdexPointType }),
					location,
				},
			},
		}).fire();
	};

	useEffect(() => {
		invariant(ref.current);
		return combine(
			draggable({
				element: ref.current,
				onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
					scrollJustEnoughIntoView({ element: source.element });
					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
						render: ({ container }) => {
							// eslint-disable-next-line react/no-deprecated
							ReactDOM.render(<DragPreview ref={refPreview} />, container);
							if (refPreview.current) {
								refPreview.current.innerHTML = ref.current?.innerHTML ?? '';
							}
							// eslint-disable-next-line react/no-deprecated
							return () => ReactDOM.unmountComponentAtNode(container);
						},
						nativeSetDragImage,
					});
				},
				onDragStart: () => {
					setDraggableState('dragging');
					onDragStart && onDragStart();
				},
				getInitialData: () => ({
					id: item.id,
					type: SHORTCUTS_ITEM_TYPE,
				}),
				// attach a url for other documents and applications
				getInitialDataForExternal: () => {
					if (!url) {
						return {};
					}
					return {
						'text/uri-list': url,
					};
				},
				onDrop: () => {
					setDraggableState('idle');
				},
			}),
			dropTargetForElements({
				element: ref.current,
				canDrop: ({ source }) => {
					return source.data.type === SHORTCUTS_ITEM_TYPE;
				},
				getData: ({ input, element }) => {
					const data = {
						id: item.id,
						type: SHORTCUTS_ITEM_TYPE,
						index,
					};
					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag: ({ self, source, location }) => {
					if (
						source.data.id !== item.id &&
						location?.current?.dropTargets[0]?.data?.id === item.id
					) {
						setClosestEdge(extractClosestEdge(self.data));
					} else {
						setClosestEdge(null);
					}
				},
				onDragLeave: () => {
					setClosestEdge(null);
				},
				onDrop: ({ location }) => {
					if (location?.current?.dropTargets[0]) {
						const { data: target } = location.current.dropTargets[0];
						const { data: source } = location.initial.dropTargets[0];
						const edge: Edge | null = extractClosestEdge(target);
						if (
							!target ||
							!target.id ||
							target.type !== SHORTCUTS_ITEM_TYPE ||
							(target.id === item.id && !edge)
						) {
							return;
						}
						const sourcePosition = {
							index: source.index as number,
						};

						const destinationPosition = {
							index: getReorderDestinationIndex({
								startIndex: source.index as number,
								indexOfTarget: target.index as number,
								closestEdgeOfTarget: edge,
								axis: 'vertical',
							}),
						};
						if (sourcePosition.index !== destinationPosition.index) {
							onDragEnd(sourcePosition, destinationPosition);
						}
					}
					setClosestEdge(null);
				},
			}),
		);
	}, [onDragStart, onDragEnd, item, index, url]);

	const disableNativeDragOnLinks = () => {
		if (!ref.current) return;
		Array.from(ref.current.querySelectorAll('a')).forEach((anchor) =>
			anchor.setAttribute('draggable', 'false'),
		);
	};

	const handleComplete = () => {
		disableNativeDragOnLinks();
	};

	const handleCompleteError = () => {
		disableNativeDragOnLinks();
	};

	const itemContent = (
		<>
			<CardComponent
				testId="shortcut-item"
				appearance="inline"
				url={url || ''}
				placeholder={title || undefined}
				onClick={sendShortcutClickedAnalytics(navdexPointType)}
				onResolve={handleComplete}
				onError={handleCompleteError}
				ui={{
					hideBackground: true,
					hideElevation: true,
					hidePadding: true,
					clickableContainer: true,
					size: isNav4Enabled ? SmartLinkSize.Medium : SmartLinkSize.Large,
				}}
			>
				<TitleBlock
					direction={SmartLinkDirection.Horizontal}
					maxLines={1}
					position={SmartLinkPosition.Center}
					text={title || undefined}
					anchorTarget={target}
					hideTitleTooltip
					actions={
						isSpaceAdmin
							? [
									{
										name: ActionName.EditAction,
										onClick: openEdit,
										iconPosition: 'before',
										testId: 'editAction',
									},
									{
										name: ActionName.DeleteAction,
										onClick: () => deleteShortcut(item),
										iconPosition: 'before',
										testId: 'deleteAction',
										content: intl.formatMessage(i18n.shortcutsQuickActionsRemoveButton),
									},
								]
							: undefined
					}
					showActionOnHover
					onActionMenuOpenChange={({ isOpen }) => {
						setIsActionMenuOpen(isOpen);
					}}
				/>
			</CardComponent>
			<Popup
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={false}
				isOpen={isEditOpen}
				onClose={() => handleClose()}
				content={(props) => (
					<div>
						<LazyLinkPicker
							{...props}
							plugins={plugins}
							onSubmit={({ url, displayText }) => {
								onSubmit(url, displayText);
							}}
							onCancel={handleClose}
							url={url || ''}
							displayText={title || ''}
						/>
					</div>
				)}
				placement="bottom-start"
				offset={[-42, 4]}
				trigger={(triggerProps) => (
					<PopupTrigger {...triggerProps}>&nbsp;This trigger is hidden</PopupTrigger>
				)}
			/>
			<Blanket
				data-testid="space-shortcuts-actions-blanket"
				isTinted={false}
				shouldAllowClickThrough={!isEditOpen && !isActionMenuOpen}
				onBlanketClicked={() => {
					setIsActionMenuOpen(false);
					setIsEditOpen(false);
				}}
			/>
			{closestEdge ? <DropIndicator edge={closestEdge} /> : null}
		</>
	);

	const isMenuOpen = isActionMenuOpen || isEditOpen;

	if (isNav4Enabled) {
		return (
			<Box
				as="li"
				ref={ref}
				aria-label={intl.formatMessage(i18n.draggableItemLabel, { title })}
				xcss={cx(itemStyles.base, itemStyles[draggableState], isMenuOpen && itemStyles.isMenuOpen)}
			>
				{itemContent}
			</Box>
		);
	}

	return (
		<ShortcutsItemContainerNav3
			key={key}
			ref={ref}
			aria-label={intl.formatMessage(i18n.draggableItemLabel, { title })}
			data-is-menu-open={isMenuOpen}
			draggableState={draggableState}
		>
			{itemContent}
		</ShortcutsItemContainerNav3>
	);
};

export const DraggableShortcutsItem = withFlags(ShortcutsItemComponent);
